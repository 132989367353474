import Accordions from './components/accordion'
import Anchors from './components/anchors'
import CountUp from './components/countUp'
import Dialogs from './components/dialog'
// import NavScroll from './components/navScroll'

// accessibility enhancements
Accordions()
Anchors()
Dialogs()

// gsap
CountUp()
// NavScroll()
console.info('%c🔥CK Started!', 'color: red; font-size: 2rem;')
