import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/all'

gsap.registerPlugin(ScrollTrigger)

/**
 * Helper function that initializes animations on elements with the attribute 'ck-countup-element'
 */
const initAnimations = () => {
  // Selects all elements with the attribute 'ck-countup-element'
  document.querySelectorAll('[ck-countup-element]').forEach((element) => {
    // The following lines get various configuration attributes or set defaults
    const startCount = Number(element.getAttribute('ck-countup-start')) || 0
    const endCount = Number(element.getAttribute('ck-countup-end')) || Number(element.textContent) || 100
    const snapValue = Number(element.getAttribute('ck-countup-snap')) || 1
    const duration = Number(element.getAttribute('ck-countup-duration')) || 5

    // Creates a timeline for the animation with a scroll trigger
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: 'top bottom',
        end: 'bottom top',
      },
    })

    /**
     * Updates innerHTML of number element with formatted variable
     */
    const formatStat = ({ count }: typeof state, target: Element) => {
      if (target.id === 'stat1') {
        target.innerHTML = count.toFixed().toString() + '%'
      } else if (target.id === 'stat2') {
        target.innerHTML = '$' + count.toFixed().toString() + 'M'
      } else if (target.id === 'stat3') {
        target.innerHTML = (Number(count) / 1000000).toFixed(1) + 'M'
      } else {
        target.innerHTML = count.toFixed().toString()
      }
    }

    const state = { count: startCount }
    tl.fromTo(
      state,
      { count: startCount },
      {
        count: endCount,
        duration: duration,
        ease: 'power2',
        snap: { count: snapValue },
        onUpdate: formatStat,
        onUpdateParams: [state, element],
      },
    )
  })
}

/**
 * Main function that initiates the animation by calling initAnimations function
 */
export default function countUp() {
  initAnimations()
}
